<template>
  <form>
    <p class="mt-0">Number of 8-hour shifts</p>
    <b-form-select v-model="evaluation.number_of_shifts"
                   :options="shiftsOptions"/>

    <p>Acceptance mark</p>
    <extended-radio-group v-model="evaluation.acceptance_mark"
                          :options="acceptanceOptions"/>

    <p>Comment<span class="grey--text font-italic"> - Optional</span></p>
    <b-form-input v-model="evaluation.comment"
                  class="mb-3"/>
  </form>
</template>

<script>
  import ExtendedRadioGroup from '@/components/extended-radio-group';
  import { adaptListOfNamesToRadioOptions } from '@/helpers';

  export default {
    name: 'RapidAccessEvaluationFinalForm',
    components: { ExtendedRadioGroup },
    props: {
      value: {
        type: Object,
        require: true,
      },
    },
    data() {
      return {
        evaluation: {},
        maxNumberOfShifts: 15,
        acceptanceOptionsTexts: ['approved', 'rejected'],
      };
    },
    computed: {
      shiftsOptions() {
        return this.$_.range(this.maxNumberOfShifts + 1);
      },
      acceptanceOptions() {
        return adaptListOfNamesToRadioOptions(this.acceptanceOptionsTexts);
      },
    },
    watch: {
      value: {
        handler() {
          this.evaluation = this.value;
        },
        immediate: true,
      },
      evaluation: {
        handler() {
          this.$emit('input', this.evaluation);
        },
      },
    },
  };
</script>

<style scoped>
  p {
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
</style>
