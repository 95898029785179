<template>
  <div class="container">
    <rapid-access-evaluation-final-table v-model="proposals"
                                         :proposals-loading="proposalsLoading"
                                         @open-submit-confirmation-modal="openConfirmationModal"
                                         @open-rapid-access-evaluation-final-modal="openEvaluationModal"/>

    <rapid-access-evaluation-final-modal ref="rapidAccessEvaluationFinalModal"/>

    <base-submit-danger-modal ref="submitConfirmationModal"
                              :confirm-function="submitEvaluation"
                              class="modal-class"
                              confirm-button-title="Confirm"
                              modal-title="Confirm submission">
      <p class="my-1 p-1 text-center">Do you want to submit evaluation of this proposal?</p>
      <p class="p-1 warning-text">Submitting is final and cannot be revoked.</p>
    </base-submit-danger-modal>
  </div>
</template>

<script>
  import RapidAccessEvaluationFinalTable from '@/components/evaluation/rapidAccess/RapidAccessEvaluationFinalTable';
  import RapidAccessEvaluationFinalModal from '@/components/evaluation/rapidAccess/RapidAccessEvaluationFinalModal';
  import BaseSubmitDangerModal from '@/components/baseComponents/baseModals/BaseSubmitDangerModal';
  import { mapState } from 'vuex';

  export default {
    name: 'RapidAccessEvaluationFinal',
    components: {
      RapidAccessEvaluationFinalTable,
      RapidAccessEvaluationFinalModal,
      BaseSubmitDangerModal,
    },
    data() {
      return { proposalsLoading: false };
    },
    computed: {
      infrastructure() {
        const routePathElements = this.$route.path.split('/');
        return routePathElements[routePathElements.length - 1];
      },
      ...mapState('rapidAccessEvaluation', { proposals: 'finalEvaluationProposals' }),
    },
    methods: {
      async loadProposals() {
        this.proposalsLoading = true;
        await this.$store.dispatch('rapidAccessEvaluation/loadFinalEvaluationProposals', this.infrastructure);
        this.proposalsLoading = false;
      },
      async submitEvaluation() {
        await this.$store.dispatch('rapidAccessEvaluation/submitSelectedProposalFinalEvaluation');
        this.closeConfirmationModal();
      },
      openConfirmationModal() {
        this.$refs.submitConfirmationModal.show();
      },
      closeConfirmationModal() {
        this.$refs.submitConfirmationModal.hide();
      },
      openEvaluationModal() {
        this.$refs.rapidAccessEvaluationFinalModal.show();
      },
    },
    created() {
      this.loadProposals();
    },
  };
</script>

<style scoped>
  .container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .warning-text {
    color: red;
    text-align: center;
  }
</style>
