<template>
  <base-submit-info-modal ref="rapidAccessEvaluationFinalModal"
                          :confirm-function="saveEvaluation"
                          confirm-button-title="Save evaluation"
                          modal-title="Final rapid access evaluation">
    <template>
      <rapid-access-evaluation-final-form v-model="evaluation"/>
    </template>
  </base-submit-info-modal>
</template>

<script>
  import BaseSubmitInfoModal from '@/components/baseComponents/baseModals/BaseSubmitInfoModal';
  import RapidAccessEvaluationFinalForm from '@/components/evaluation/rapidAccess/RapidAccessEvaluationFinalForm';
  import { mapGetters } from 'vuex';

  export default {
    name: 'RapidAccessEvaluationFinalModal',
    components: {
      BaseSubmitInfoModal,
      RapidAccessEvaluationFinalForm,
    },
    data() {
      return { evaluation: {} };
    },
    computed: { ...mapGetters('rapidAccessEvaluation', ['finalEvaluationSelectedProposalInhouseGrade']) },
    watch: {
      finalEvaluationSelectedProposalInhouseGrade: {
        handler() {
          this.evaluation = { ...this.finalEvaluationSelectedProposalInhouseGrade };
        },
      },
    },
    methods: {
      hide() {
        this.$refs.rapidAccessEvaluationFinalModal.hide();
      },
      async saveEvaluation() {
        await this.$store.dispatch('rapidAccessEvaluation/saveSelectedProposalFinalEvaluation', this.evaluation);
        this.hide();
      },
      show() {
        this.$refs.rapidAccessEvaluationFinalModal.show();
      },
    },
  };
</script>

<style scoped>

</style>
