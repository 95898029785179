<template>
  <base-icon-button v-on="$listeners"
                    :title="title">
    <base-material-design-icon icon="picture_as_pdf"/>
  </base-icon-button>
</template>

<script>
  import BaseIconButton from '@/components/baseComponents/baseIcons/BaseIconButton';
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';

  export default {
    name: 'BaseIconButtonPictureAsPdf',
    components: { BaseMaterialDesignIcon, BaseIconButton },
    props: {
      title: {
        type: String,
        default: 'Proposal PDF',
        validator: (value) => value.length > 0,
      },
    },
  };
</script>

<style scoped>

</style>
