<template>
  <div>
    <b-form-radio-group v-if="!disabled"
                        id="radio-group"
                        v-model="valueProp"
                        :class="{'buttons-group': responsive, 'invalid': isInvalid.$error}"
                        :disabled="disabled"
                        :size="size"
                        buttons
                        @input="$emit('input', valueProp); isInvalid.$touch()">
      <b-form-radio v-for="(option, index) in options"
                    :key="index"
                    :button-variant="option.buttonVariant"
                    :class="{'buttons': responsive}"
                    :text="option.text"
                    :value="option['value']">
        {{ option.text }}
      </b-form-radio>
    </b-form-radio-group>
    <div v-if="disabled && valueProp != undefined"
         class="font-weight-bold mt-3 mb-4 btn btn-info">
      {{ selectedOptionText }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: '',
      disabled: false,
      options: Array,
      defaultOption: {
        type: String,
        default: '',
      },
      isInvalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      size: '',
      responsive: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return { valueProp: this.defaultOption ? this.defaultOption : this.value };
    },
    computed: {
      selectedOptionText() {
        const selectedOption = this.options.find((element) => element.value === this.valueProp);
        return selectedOption.text;
      },
    },
    watch: {
      valueProp() {
        this.$emit('input', this.valueProp);
      },
      value() {
        this.valueProp = this.value;
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .buttons-group {
    display: block;
  }

  .buttons {
    float: left;
    display: block;
    border-radius: 0.4rem !important;
    @media screen and (min-width: 992px) {
      margin: 0 2% 1rem 2%;
      width: 48%;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
